<template>
  <div class="text-decoration-none h-100" :id="product.attributes.number">
    <div class="product-card d-flex position-relative h-100">
      <div class="position-absolute mt-4 icon-card">
        <div class="d-flex flex-column">
          <!-- <tool-tip
            :interactive="true"
            :hover="true"
            class="m-0 mb-2 horizontal"
            placement="right"
            content="Numero del Lote"
          >
            <badge-sale
              v-if="product.type == typeLots"
              class=""
              color="bg-gray"
            >
              <span style="font-size: 12px" class="text-white">
                {{ product.attributes.number_text }}
              </span>
            </badge-sale>
          </tool-tip> -->
          <tool-tip
            :interactive="true"
            :hover="true"
            class="m-0 horizontal"
            placement="right"
            content="Estatus del Producto"
          >
            <badge-sale
              v-if="showBadgeStatus"
              :color="setColor(product.attributes.status_type)"
              :text="
                product.type == typeLots
                  ? product.attributes.status_text
                  : product.attributes.status
              "
            ></badge-sale>
          </tool-tip>
          <tool-tip
            :interactive="true"
            :hover="true"
            class="m-0 horizontal"
            placement="right"
            content="Mínimo del Producto"
          >
            <badge-sale v-if="!showBadgeStatus" color="bg-success">
              <span style="font-size: 12px" class="text-white">
                {{ product.attributes.min ? `Mínimo` : "Sin Mínimo" }}
              </span>
            </badge-sale>
          </tool-tip>
          <tool-tip
            :interactive="true"
            :hover="true"
            class="m-0 horizontal"
            placement="right"
            content="Precio Mínimo del Producto"
          >
            <badge-sale
              v-if="!showBadgeStatus && product.attributes.min"
              class="mt-2"
              color="bg-theme"
            >
              <div style="font-size: 12px" class="text-white">
                <b>
                  {{
                    product.attributes.min
                      ? `${currencyFormatMin(product)}`
                      : ""
                  }}
                </b>
              </div>
            </badge-sale>
          </tool-tip>
          <tool-tip
            :interactive="true"
            :hover="true"
            class="m-0 horizontal"
            placement="right"
            content="IVA del Producto"
          >
            <badge-sale
              class="mt-2"
              v-if="product.attributes.iva_number"
              color="primary-chip"
              text="+ IVA
            "
            ></badge-sale>
          </tool-tip>
          <tool-tip
            :interactive="true"
            :hover="true"
            class="m-0 horizontal"
            placement="right"
            :content="product.type == typeLots ? 'Ver Lote' : 'Ver Producto'"
          >
            <div
              class="mt-3 fa-2x rounded-circle wrapper d-flex justify-content-center align-items-center"
              @click="actionCard"
            >
              <font-awesome-icon color="#ea7f23" :icon="['fas', 'eye']" />
            </div>
          </tool-tip>
        </div>
      </div>
      <span class="text-decoration-none" @click="actionCard">
        <div class="card position-relative border-0">
          <img
            class="show"
            :src="`${product.attributes.portada || getLogoDefault}`"
            :alt="product.attributes.name"
          />
        </div>
      </span>
      <div
        class="product-card-title my-auto ms-4 pt-2 pb-2 position-relative h-100"
      >
        <div class="position-relative elbordaro">
          <h4 class="m-0 px-5">
            <span class="text-decoration-none" @click="actionCard">
              {{ product.attributes.name }}
            </span>
          </h4>
          <div class="">
            <p class="mt-4 px-5" v-html="product.attributes.description"></p>
            <div class="mt-4 px-4 d-flex align-items-center">
              <div class="fw-bold mx-4" v-if="product.type == typeProducts">
                {{
                  product.relationships.currency.code !== "UF"
                    ? product.relationships.currency.code
                    : ""
                }}
                {{ product.attributes.amount_format }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BadgeSale from "../../atoms/Badge/BadgeSale.vue";
import ToolTip from "../../atoms/ToolTip.vue";
import ProductCard from "../Product/ProductCard.vue";

export default {
  components: { BadgeSale, ToolTip },
  mixins: [ProductCard],
  props: {
    description: {
      type: String,
    },
  },
  computed: {
    getLogoDefault() {
      return require(this.$store.getters.nameApp !== "casalira"
        ? `@/assets/images/logos/logo.jpeg`
        : `@/assets/images/logos/logo.png`);
    },
  },
};
</script>
<style scoped="scoped">
.product-card {
  background: white;
  width: 100%;
}
.card {
  width: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  height: 300px !important;
  width: 300px;
  border-radius: 0 !important;
}
.product-title {
  background: #fff;
  line-height: 24px;
  color: #242424;
}
.prod-card-counter {
  transition: opacity 0.25s ease-in-out;
  top: -60px;
}
.product-card:hover .prod-card-counter {
  opacity: 0;
}
.product-title span:hover {
  color: var(--primary-color);
  transition: all 0.3s ease-out;
}
.product-title span {
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.show,
.hide {
  filter: brightness(70%) !important;
  transition: all 0.25s ease-in-out;
}

.product-card:hover .show {
  visibility: visible;
  opacity: 1;
  transform: scale(1.1);
}

.product-card img {
  width: 100%;
  height: 100%;
}
.product-card span {
  color: #484848;
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  transition: color 0.25s ease-in-out;
}
.product-card span:hover {
  color: var(--primary-color);
}
.icon-card {
  left: 5px;
  z-index: 1;
}

.details {
  overflow: hidden;
  padding: 5px 5px 10px;
  background: #fff;
  color: #606060;
}

.product-card {
  overflow: hidden;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
}
@media (max-width: 767.98px) {
  .prod-card-counter {
    top: -55px;
  }
  .Counter {
    margin-top: 25px;
    width: 100%;
    display: flex;
  }
  .product-title a {
    font-size: 15px !important;
  }
  .product-title {
    padding-top: 0 !important;
    padding: 20px 5px 0;
  }
  .details {
    font-size: 12px !important;
  }
  .elbordaro {
    margin-top: 10px;
  }
}
</style>
