<template>
  <layout-default>
    <div class="container">
      <div class="mt-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6 col-sm-12">
              <card-simple>
                <section
                  class="responsive-iframe"
                  v-if="auction && auction.attributes.status_number !== 4"
                >
                  <div
                    class="fluidMedia"
                    v-if="auction && auction.attributes.streaming_url"
                    v-html="auction.attributes.streaming_url"
                  ></div>
                </section>
                <h4 v-else class="text-center py-4">
                  <font-awesome-icon class="mx-2" :icon="['fas', 'pause']" />
                  Remate en Pausa
                </h4>
                <!-- Information user auction -->
                <section class="" v-if="lotInAuction">
                  <div
                    class="d-flex justify-content-center py-2"
                    v-if="amount_winner_lot > 0"
                  >
                    <div class="mt-3 mx-2">
                      <b> Oferta ganando: </b>
                    </div>
                    <div class="text-center align-self-center mx-2">
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <div class="rounded-circle bg-theme icon-label">
                          <font-awesome-icon
                            class="text-white text-center"
                            :icon="['fas', 'user']"
                          />
                        </div>
                        <label class="mt-2">
                          <p class="" v-html="name_winner_lot"></p>
                        </label>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <div class="rounded-circle bg-theme icon-label">
                          <font-awesome-icon
                            class="text-white text-center"
                            :icon="['fas', 'dollar-sign']"
                          />
                        </div>
                        <label class="mt-2">
                          <p
                            class=""
                            v-html="currencyFormat(amount_winner_lot)"
                          ></p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-center my-4">Sin ofertas Actualmente</p>
                    <p class="text-center" v-if="lotInAuction">
                      <b v-if="lotInAuction.attributes.min">Monto mínimo: </b
                      >{{ lotInAuction.attributes.minimum }}
                    </p>
                  </div>

                  <!-- CountDown de Adjudicación -->
                  <div
                    v-if="timerAdj"
                    class="d-flex justify-content-center align-items-center py-2"
                  >
                    <h4 v-if="countdownAdj > 3">
                      Tiempo restante para adjudicar:
                      {{ countdownAdj - 3 }} seg.
                    </h4>
                    <h4 v-else>Procesando adjudicación</h4>
                  </div>

                  <template v-if="check">
                    <!-- Oferta acciones -->

                    <!--
                CONDICION: Solo cuando el lote no tenga minimo y la puja no se haya iniciado se mostrará

                Inicio
             -->

                    <div
                      class="d-flex justify-content-center align-items-center pt-2"
                      :class="{ 'pb-5': isUser }"
                      v-if="
                        amount_winner_lot == 0 &&
                        lotInAuction.attributes.min == null &&
                        countdownAdj > 3
                      "
                    >
                      <div class="mx-2 mt-4 text-center">
                        <b>Oferta por:</b>
                      </div>
                      <div class="align-self-center mx-2">
                        <button-filled
                          v-show="lotInAuction && (isPart || isAdmin)"
                          type="button"
                          class="fw-bold text-white mt-4 ml-5"
                          @click="newBid('bid_one')"
                          :hasRoundedCorners="true"
                        >
                          <small class="mx-2"
                            >+
                            {{
                              lotInAuction
                                ? currencyFormat(
                                    lotInAuction.attributes.bid_one
                                  )
                                : 0
                            }}
                          </small>
                        </button-filled>
                      </div>
                      <div
                        class="self-center mx-2"
                        v-if="lotInAuction && lotInAuction.attributes.bid_two"
                      >
                        <button-filled
                          v-show="lotInAuction && (isPart || isAdmin)"
                          type="button"
                          class="fw-bold text-white mt-4 ml-5"
                          @click="newBid('bid_two')"
                          :hasRoundedCorners="true"
                        >
                          <small class="mx-2"
                            >+
                            {{
                              lotInAuction
                                ? currencyFormat(
                                    lotInAuction.attributes.bid_two
                                  )
                                : 0
                            }}
                          </small>
                        </button-filled>
                      </div>
                    </div>

                    <!-- FIN -->

                    <!--
               CONDICION: Solo cuando ya se haya iniciado la puja y el contador sea mayor a 3

               INICIO

              -->

                    <div
                      class="d-flex justify-content-center align-items-center pt-2"
                      :class="{ 'pb-5': isUser }"
                      v-if="amount_winner_lot > 0 && countdownAdj > 3"
                    >
                      <div class="mt-4 mx-2">
                        <b>Oferta por:</b>
                      </div>
                      <div class="align-self-center mx-2">
                        <button-filled
                          v-show="lotInAuction && (isPart || isAdmin)"
                          type="button"
                          class="fw-bold text-white mt-4 ml-5"
                          @click="newBid('bid_one')"
                          :hasRoundedCorners="true"
                        >
                          <small class="mx-2"
                            >+
                            {{
                              lotInAuction
                                ? currencyFormat(
                                    lotInAuction.attributes.bid_one
                                  )
                                : 0
                            }}
                          </small>
                        </button-filled>
                      </div>
                      <div
                        class="self-center mx-2"
                        v-if="lotInAuction && lotInAuction.attributes.bid_two"
                      >
                        <button-filled
                          v-show="lotInAuction && (isPart || isAdmin)"
                          type="button"
                          class="fw-bold text-white mt-4 ml-5"
                          @click="newBid('bid_two')"
                          :hasRoundedCorners="true"
                        >
                          <small class="mx-2"
                            >+
                            {{
                              lotInAuction
                                ? currencyFormat(
                                    lotInAuction.attributes.bid_two
                                  )
                                : 0
                            }}
                          </small>
                        </button-filled>
                      </div>
                    </div>

                    <!-- FIN -->

                    <!-- Boton para mostrar minimum y mandar el minimo si es que hay -->
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-else-if="
                        amount_winner_lot == 0 && lotInAuction.attributes.min
                      "
                    >
                      <div class="mt-4 mx-2">
                        <b>Oferta por:</b>
                      </div>
                      <div class="align-self-center mx-2">
                        <button-filled
                          v-show="lotInAuction && (isPart || isAdmin)"
                          type="button"
                          class="fw-bold text-white mt-4 ml-5"
                          @click="newBid('bid_one')"
                          :hasRoundedCorners="true"
                        >
                          <small class="mx-2"
                            >{{
                              lotInAuction
                                ? lotInAuction.attributes.minimum
                                  ? lotInAuction.attributes.minimum
                                  : currencyFormat(
                                      lotInAuction.attributes.bid_one
                                    )
                                : 0
                            }}
                          </small>
                        </button-filled>
                      </div>
                    </div>
                  </template>
                </section>
                <slot
                  name="admin-actions"
                  :winner="name_winner_lot"
                  :users-conected="usersConected"
                  :lot-in-auction="lotInAuction"
                ></slot>
              </card-simple>
              <section class="mt-5">
                <h3 class="mb-4">Lotes Adjudicados</h3>
                <custom-table
                  :headers="headersAccretitedLotsWraped"
                  :items="accreditedLots"
                />
              </section>
            </div>
            <div class="col-md-6 col-sm-12" v-if="auction">
              <card>
                <template v-slot:title>
                  <h5>
                    {{ auction.attributes.name }}
                  </h5>
                </template>
                <template v-slot:body>
                  <div>
                    <tab-wrapper class="pt-2">
                      <tab class="mt-5" :title="'Lote Actual'">
                        <section class="current-lot">
                          <div v-if="lotInAuction">
                            <div class="container">
                              <product-slider
                                class="product-image"
                                :photos="lotInAuction.relationships.images"
                              ></product-slider>
                            </div>
                            <div
                              class="d-flex align-items-center justify-content-start mt-3"
                            >
                              <div
                                class="rounded-circle bg-theme icon-label mx-3"
                              >
                                <font-awesome-icon
                                  class="text-white text-center"
                                  :icon="['fas', 'exclamation']"
                                />
                              </div>
                              <label class="mt-2">
                                <h5
                                  class=""
                                  v-html="lotInAuction.attributes.name"
                                ></h5>
                              </label>
                            </div>
                            <div
                              class="d-flex align-items-center justify-content-start"
                            >
                              <label class="mt-2 p-3">
                                <p
                                  class=""
                                  v-html="lotInAuction.attributes.description"
                                ></p>
                              </label>
                            </div>
                          </div>
                          <section v-else>
                            <div class="container">
                              <h5 class="text-center my-3">
                                {{ message_preview }}
                              </h5>
                            </div>
                          </section>
                        </section>
                      </tab>
                      <tab :title="'Próximos Lotes'">
                        <custom-table
                          class="mt-3"
                          :headers="headersLots"
                          :items="nextLots"
                        />
                      </tab>
                      <tab :title="'Lotes Rematados'">
                        <custom-table
                          class="mt-3"
                          :headers="headersLots"
                          :items="awardLots"
                        />
                      </tab>
                    </tab-wrapper>
                  </div>
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import CardSimple from "@/components/molecules/Card/CardSimple.vue";
import CustomTable from "@/components/molecules/Table/CustomTable.vue";
import TabWrapper from "@/components/molecules/Tab/TabWrapper.vue";
import Tab from "@/components/molecules/Tab/TabContent.vue";
import Card from "@/components/molecules/Card/Card.vue";
import ProductSlider from "@/components/atoms/Carousel/ProductSlider.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";

export default {
  components: {
    LayoutDefault,
    CardSimple,
    CustomTable,
    TabWrapper,
    Tab,
    Card,
    ProductSlider,
    ButtonFilled,
  },
  name: "PlaygroundComponent",
  inject: ["$echo"],
  data() {
    return {
      //Numero de usuarios conectados al streaming
      usersConected: 0,
      //Currencys
      PESO_CURRENCY: 1,
      UF_CURRENCY: 3,

      //Participant
      isPart: false,

      //Winner Lot
      name_winner_lot: "",
      amount_winner_lot: "",
      // comuna_winner_lot: "",

      //info remate
      slug: this.$route.params.slug,
      tab: null,
      labels: {
        description: "Descripción del lote",
        name: "Articulo del lote",
        number: "Numero del lote",
      },

      //Lot in auction
      lotInAuction: null,
      amount_actual: 0,
      message_preview: "Estamos por iniciar..",

      //lots
      headersLots: [
        { text: "Nombre", value: "attributes.name" },
        {
          text: "Descripción",
          value: "attributes.description",
        },
        {
          text: "Mínimo",
          value: "attributes.minimum",
        },
      ],
      headersAccretitedLots: [
        { text: "Artículo", value: "name" },
        {
          text: "Valor de compra",
          value: "amount_format",
        },
      ],
      accreditedLots: [],

      // ContDown Adjudicacion
      timerAdj: null,
      countdownAdj: 63,
    };
  },
  async created() {
    await this.getAuction();
    await this.isParticipant();
    await this.getAccreditedLots();

    //Funcion para actualizar numero de usuarios conectados al streaming
    this.$echo
      .join(`casa_lira_channel-test`)
      .here((users) => {
        this.usersConected = users.length;
      })
      .joining(() => {
        this.usersConected += 1;
      })
      .leaving(() => {
        this.usersConected -= 1;
      })
      .error((error) => {
        console.error(error);
      });

    // Evento Realtime para actualizar el status del remate si es actualizado por el administrador
    this.$echo
      .channel("casa_lira_channel-test")
      .listen("AuctionStatusUpdated", ({ status, resume_auction }) => {
        //Si esta pausado o se reanudura, solo se cambia el status con un Commit
        if (status == 4 || resume_auction) {
          //Seteamos el status nuevo
          this.commitNewStatus(status);
        } else if (status == 1) {
          //Si es el status de inicio traemos el primer lote
          this.getLotInAuction();
          //Seteamos el status iniciado
          this.commitNewStatus(status);
        } else {
          this.$store.commit("lot/SET_LOT_IN_AUCTION", null);
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: "¡El Remate ha finalizado! Gracias por participar..",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            this.$router.push({
              name: "Auctions",
              params: { slug: this.$route.params.slug },
            });
          });
        }
      });

    // Evento Realtime para actualizar el playground cada vez que se realice una puja
    this.$echo
      .channel("casa_lira_channel-test")
      .listen("UserNewBidEvent", ({ bid }) => {
        this.updateBidWinner(bid);
      });

    // Evento Realtime para actualizar el playground cuando se adjudique el lote
    this.$echo
      .channel("casa_lira_channel-test")
      .listen("AwardLot", ({ message }) => {
        this.lotInAuction = null;
        this.message_preview = message;
        this.$store.commit("auction/SET_AWARD_LOT");

        // Detener y limpiar contador
        this.stopCountDownAd();

        // Actualizar lista de lotes adjudicados
        this.getAccreditedLots();
      });

    // Evento Realtime para actualizar el playground cuando se traiga el siguiente lote
    this.$echo
      .channel("casa_lira_channel-test")
      .listen("NextLot", ({ next_lot }) => {
        this.lotInAuction = next_lot;
        this.amount_winner_lot = "";
        this.name_winner_lot = "";
        this.amount_actual = next_lot.attributes.automatic_bid;
        this.$store.commit("auction/SET_ACTIVE_LOT", next_lot);
        this.$store.commit("lot/SET_LOT_IN_AUCTION", next_lot);
      });
  },
  methods: {
    ...mapActions({
      fetchAuction: "auction/fetchAuction",
      commitNewStatus: "auction/commitNewStatus",
      fetchLotInAuction: "lot/fetchLotInAuction",
      pushNewBid: "lot/pushNewBid",
      pushAwardLot: "lot/awardLot",
    }),
    async isParticipant() {
      if (this.isUser) {
        try {
          const { data } = await axios.get(
            `/api/auctions/${this.slug}/participants/${this.user.id}?status=1`
          );
          this.isPart = data.is;
        } catch (e) {
          console.log(e);
        }
      }
    },

    // Formato de moneda
    currencyFormat(value) {
      let simbol =
        this.lotInAuction?.attributes?.currency_id == this.UF_CURRENCY
          ? "UF"
          : "$";
      return simbol + new Intl.NumberFormat("es-CL").format(value);
    },

    //Actualizar la puja ganadora
    async updateBidWinner({ bid, user }) {
      this.name_winner_lot = `${user.name} ${user.lastname}`;
      this.amount_winner_lot = bid.amount;
      this.amount_actual =
        this.amount_actual + this.lotInAuction.attributes.automatic_bid;

      // Iniciar CountDown
      await this.stopCountDownAd();
      this.startCountDownAd();
    },

    // Metodo para enviar una puja
    newBid(bid) {
      if (this.isPart || this.isAdmin) this.pushNewBid(bid);
    },

    startCountDownAd() {
      this.timerAdj = setInterval(() => {
        if (this.countdownAdj > 0) {
          this.countdownAdj--;
        }
        if (this.countdownAdj === 0 && this.isAdmin) {
          this.pushAwardLot();
          // this.stopCountDownAd();
        }
      }, 1000);
    },

    stopCountDownAd() {
      clearInterval(this.timerAdj);
      this.countdownAdj = 63;
      this.timerAdj = null;
    },

    setDataLotInAuction() {
      if (this.lotInAuction && this.lotInAuction.attributes.last_user_bid) {
        this.name_winner_lot = `${this.lotInAuction.attributes.last_user_bid.name} ${this.lotInAuction.attributes.last_user_bid.lastname}`;
        this.amount_winner_lot =
          this.lotInAuction.attributes.last_user_bid.pivot.amount;
        this.amount_actual =
          this.lotInAuction.attributes.last_user_bid.pivot.amount +
          this.lotInAuction.attributes.automatic_bid;
      } else {
        this.amount_actual = this.lotInAuction
          ? this.lotInAuction.attributes.automatic_bid
          : 0;
      }
    },

    async getLotInAuction(loader = null) {
      this.lotInAuction = await this.fetchLotInAuction();
      this.setDataLotInAuction();
      if (this.lotInAuction)
        this.$store.commit("auction/SET_ACTIVE_LOT", this.lotInAuction);
      if (loader) {
        loader.hide();
      }
    },

    async getAuction() {
      let loader = this.$loading.show();
      const auction = await this.fetchAuction(this.slug);
      //Si ya inicio traemos el lote en remate
      if (auction.attributes.status === "Activo") {
        this.getLotInAuction(loader);
        return;
      }
      loader.hide();
    },

    async getAccreditedLots() {
      if (this.check) {
        try {
          const { data } = (
            await axios.get(`/api/auctions/${this.auction.id}/lots/accredited`)
          ).data;
          this.accreditedLots = data;
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      auction: "auction/auction",
      check: "auth/check",
      role: "auth/role",
      user: "auth/user",
    }),
    isAdmin() {
      return this.check && this.role === "admin";
    },
    isUser() {
      return this.check && this.role === "user";
    },
    activeAuction() {
      if (this.auction) {
        return this.auction.attributes.status == "Activo";
      } else {
        return false;
      }
    },
    nextLots() {
      if (this.auction) {
        return this.auction.relationships.lotes.data.filter((lote) => {
          return lote.attributes.status === 1;
        });
      }
      return [];
    },
    awardLots() {
      if (this.auction) {
        return this.auction.relationships.lotes.data.filter((lote) => {
          return lote.attributes.status === 3;
        });
      }
      return [];
    },
    headersAccretitedLotsWraped() {
      if (this.isAdmin) {
        return [
          ...this.headersAccretitedLots,
          {
            text: "Adjudicado a",
            value: "winner",
          },
        ];
      }
      return this.headersAccretitedLots;
    },
  },
};
</script>
<style>
.fluidMedia {
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;
}

.fluidMedia iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ytplayer {
  pointer-events: none;
  position: absolute;
}

.responsive-iframe {
  aspect-ratio: 16/9 !important;
}

.tabs {
  padding: 0;
}

.icon-label {
  margin-right: 1rem;
  min-width: 2.5rem !important;
  padding: 0.4rem 0.5rem;
  text-align: center;
}
</style>
